import { Container, Row, Col, Button, Jumbotron } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Signin from '@components/Login/Signin'
import Signup from '@components/Login/Signup'

export default function Login({ setUser, setOnContentChange, setIsLoading }) {
  const [ tab, setTab ] = useState(1)

  useEffect(() => {
    setOnContentChange({})
  }, [ tab ])

  return (
    <Jumbotron className="m-0">
      <Container>
        <Row className="justify-content-center py-8">
          <Col md={6}>
            <h1 className="font-weight-normal logo-text text-center">
              PARO
            </h1>
            <div className="pt-5 text-center">
              <Button variant={ tab === 1 ? 'secondary' : 'outline-secondary' } disabled={tab === 1} type="submit" className="rounded-pill py-2 px-4 mx-2" onClick={() => setTab(1)}>
                Se connecter
              </Button>
              <Button variant={ tab === 2 ? 'secondary' : 'outline-secondary' } disabled={tab === 2} type="submit" className="rounded-pill py-2 px-4 mx-2" onClick={() => setTab(2)}>
                S&apos;inscrire
              </Button>
            </div>
            {
              tab === 1
                ? <Signin setUser={setUser} setIsLoading={setIsLoading} />
                : <Signup setUser={setUser} setIsLoading={setIsLoading} />
            }
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}
