import { Row, Col, Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { navigate } from 'hookrouter'
import { login } from '@services/Customers'

export default function Signin({ setUser, setIsLoading }) {
  const [ emailError, setEmailError ] = useState(undefined)
  const [ passwordError, setPasswordError ] = useState(undefined)
  const { register, getValues, handleSubmit } = useForm()

  const signInSubmit = () => {
    setIsLoading(true)
    setEmailError(undefined)
    setPasswordError(undefined)
    const { email, password } = getValues()
    login(email, password)
      .then(customer => setUser(customer))
      .then(() => navigate('/checkout'))
      .then(() => setIsLoading(false))
      .catch(error => {
        switch (error) {
        case 'bad-email-error':
          setEmailError('Aucune adresse email ne correspond.')
          break
        case 'bad-password-error':
          setPasswordError('Le mot de passe est incorrect.')
          break
        }
        setIsLoading(false)
      })
  }

  return (
    <Form className="pt-5" onSubmit={handleSubmit(signInSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Email" size="lg" className="text-sm" {...register('email', { required: true })} isInvalid={emailError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {emailError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Mot de passe" size="lg" {...register('password', { required: true })} isInvalid={passwordError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
      </Form.Group>
      <Row>
        <Col md={6}>
          {/*<Button variant="link" className="text-secondary p-0">*/}
          {/*  Mot de passe oublié ?*/}
          {/*</Button>*/}
        </Col>
        <Col md={{ span: 4, offset: 2 }}>
          <Button variant="dark" type="submit" className="rounded-pill py-2 px-4">
            Se connecter
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
