import ProductModal from '@components/Shop/ProductModal'
import { useEffect, useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { navigate } from 'hookrouter'

export default function Product({ link, product, addProductToCart, combinations }) {

  const [ count, setCount ] = useState(1)
  const [ showModal, setShowModal ] = useState(false)

  useEffect(() => {
    if (link === product.link_rewrite) {
      setShowModal(true)
    }
  }, [ link ])

  const increment = () => {
    setCount(count + 1)
  }
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1)
    }
  }

  const openDescription = () => {
    navigate(`/shop/${product.link_rewrite}`)
    setShowModal(true)
  }

  return (
    <Col lg={4} xs={6} className="text-center" key={product.id} onMouseLeave={() => setCount(1)}>
      <ProductModal product={product} showModal={showModal} setShowModal={setShowModal} addProductToCart={addProductToCart} combinations={combinations} />
      <div className="image-container">
        <img src={product.image_url} alt="" className="w-100 product-image" />
        <div className="image-overlay">
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <button className="btn btn-outline-light" onClick={() => decrement()}>
                  -
                </button>
                <p className="px-3 mb-0">
                  {count}
                </p>
                <button className="btn btn-outline-light" onClick={() => increment()}>
                  +
                </button>
              </Row>
              <Row className="d-flex justify-content-center pt-2">
                <Button variant="link" className="text-light" onClick={() => (product.associations && product.associations.product_option_values) ? openDescription() : addProductToCart(product, count)}>
                  Ajouter
                </Button>
              </Row>
              <Row className="d-flex justify-content-center pt-2">
                <Button variant="link" className="text-light" onClick={openDescription}>
                  Description
                </Button>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className="py-3">
        <h6>
          {product.name}
        </h6>
        <p className="text-content">
          {(product.associations && product.associations.product_option_values) && 'À partir de '}
          {product.priceTTC.toFixed(2)}
          €
        </p>
      </div>
    </Col>
  )
}
