import home from '@public/la-grande-poisonnerie-paro-aix-en-provence.JPG'
import home1 from '@public/coquillage-fruit-de-mer-aix-en-provence.jpg'
import home2 from '@public/poissonnier-traiteur-aix-en-provence.jpg'
import './Home.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Parallax } from 'react-parallax'
import InstagramFeed from '@components/InstagramFeed/InstagramFeed'
import MenuItem from '@components/Menu/MenuItem'

export default function Home({ setOnContentChange }) {
  return (
    <div>
      <Container fluid className="header-container py-5 d-flex flex-column justify-content-end">
        <Row className="px-lg-3">
          <Col lg={4} className="px-3 px-lg-6 menu-row d-none d-lg-block">
            <MenuItem />
          </Col>
          <Col lg={8} xs={12} className="px-0 px-lg-6 flex-column-reverse">
            <Parallax bgImage={home} strength={-100} bgImageAlt="La grande poisonnerie paro aix-en-provence">
              <div className="header-image header-lg-image" />
            </Parallax>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="py-5 px-3 flex-row-reverse">
          <Col lg={6} xs={12} className="px-3 px-lg-6 d-flex justify-content-center flex-column text-center text-lg-left">
            <h6>
              Une poissonnerie unique à Aix en Provence
            </h6>
            <p className="my-3 text-content">
              Plus qu&apos;une poissonnerie, nous vous accueillons dans un lieu de vie tourné autour du meilleur des produits de la mer. Moderne, avant-gardiste, vous trouverez les plus beaux poissons issus des plus belles pêcheries de France, mais également un incroyable rayon traiteur 100% élaboré sur place dans notre cuisine. Nous vous proposons des originalités, comme nos poissons maturés, nos sélections de caviars, poutargues, poissons fumés, et nos magnifiques plateaux de coquillages, et tout ça sur Aix-en-Provence, sur le plateau de Puyricard. Si vous aimez le poisson mais que vous souhaitez éviter de le cuisiner, vous pourrez trouver chez nous pleins de petites préparations traiteur comme les nems, tielles, tartares de poisson frais, beignets, acras, brochettes et saucisses de poissons, rillettes, terrines, brandades... Et nous saurons vous réaliser de magnifiques plateaux de fruits de mer ! Réalisés par des professionnels, nous avons une magnifique et complète gamme de coquillages, huîtres et crustacés, que nous vous dresserons à la pièce en de magnifiques compositions de plateaux de coquillages. Un lieu, mais tous les produits de la mer!
            </p>
          </Col>
          <Col lg={6} xs={12} className="px-3 px-lg-6 menu-row d-flex justify-content-end">
            <img src={home1} alt="Coquillage fruit de mer aix-en-provence" width="100%" />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="py-5 px-3 flex-row">
          <Col lg={6} xs={12} className="px-3 px-lg-6 d-flex flex-column justify-content-center text-center text-lg-right">
            <h6>
              Des artisans poissonniers écaillers à votre service
            </h6>
            <p className="my-3 text-content">
              Le métier d&apos;artisan poissonnier se doit d&apos;évoluer pour vous proposer le meilleur. Romain Paro met à votre service tout son savoir-faire. Il est l&apos;un des poissonniers le plus émérite et titré de France, Il est sélectionné au concours des meilleurs ouvriers de France en 2017, représenté en 2021, Gagnant du concours des poissonniers du Salon International de l&apos;Agriculture, Salon Agécotel Toulouse, Prix d&apos;excellence des Poissonniers de France, du Trophée Poséidon à Nantes, Poissonnier d&apos;Emeraude à Saint-Malo, gagnant du prix d&apos;esthétisme et de finesse culinaire au SIRAH, 2ème du concours de poissonnerie du SIRAH, et de nombreux autres podiums. Notre équipe, dévouée et passionnée, saura vous conseiller au mieux dans le vaste univers des produits de la mer. Service sur mesure, réalisation de toutes vos préparations minutes, conseils, expérience du produit, toutes ses valeurs se retrouvent à La Grande Poissonnerie Paro à Aix-en-Provence.
            </p>
          </Col>
          <Col lg={6} xs={12} className="px-3 px-lg-6 menu-row d-flex justify-content-start">
            <img src={home2} alt="Poissonnier traiteur aix-en-provence" width="100%" />
          </Col>
        </Row>
      </Container>
      <InstagramFeed setOnContentChange={setOnContentChange} />
    </div>
  )
}
