import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { signUp } from '@services/Customers'
import { navigate } from 'hookrouter'
import { Button, Col, Form, Row } from 'react-bootstrap'

export default function Signup({ setUser, setIsLoading }) {
  const [ emailError, setEmailError ] = useState(undefined)
  const [ passwordError, setPasswordError ] = useState(undefined)
  const [ firstnameError, setFirstnameError ] = useState(undefined)
  const [ lastnameError, setLastnameError ] = useState(undefined)
  const [ phoneError, setPhoneError ] = useState(undefined)
  const { register, getValues, handleSubmit } = useForm()

  const phoneRegex = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g
  const signUpSubmit = () => {
    setEmailError(undefined)
    setPasswordError(undefined)
    setFirstnameError(undefined)
    setLastnameError(undefined)
    setPhoneError(undefined)
    const { email, password, password2, firstname, lastname, phone, subscribe_newsletter } = getValues()
    if (password !== password2) {
      setPasswordError('Les mots de passe ne sont pas égaux.')
    } else if (password.length < 6) {
      setPasswordError('Le mot de passe est trop court (6 caractères min.).')
    } else if (firstname.length < 2) {
      setFirstnameError('Le prénom est trop court.')
    } else if (lastname.length < 2) {
      setLastnameError('Le nom de famille est trop court.')
    } else if (!phoneRegex.test(phone)) {
      setPhoneError('Le format du numéro de téléphone est incorrect.')
    } else {
      setIsLoading(true)
      signUp(email, password, firstname, lastname, phone, subscribe_newsletter)
        .then(customer => setUser(customer))
        .then(() => {
          navigate('/checkout')
          setIsLoading(false)
        })
        .catch(() => {
          setEmailError('Un compte existe avec cette adresse email.')
          setIsLoading(false)
        })
    }
  }

  return (
    <Form className="pt-5" onSubmit={handleSubmit(signUpSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Email" size="lg" className="text-sm" {...register('email', { required: true })} isInvalid={emailError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {emailError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Mot de passe" size="lg" {...register('password', { required: true })} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Confirmation de mot de passe" size="lg" {...register('password2', { required: true })} isInvalid={passwordError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Prénom" size="lg" {...register('firstname', { required: true })} isInvalid={firstnameError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {firstnameError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Nom" size="lg" {...register('lastname', { required: true })} isInvalid={lastnameError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {lastnameError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Numéro de téléphone" size="lg" {...register('phone', { required: true })} isInvalid={phoneError !== undefined} />
        <Form.Control.Feedback type="invalid">
          {phoneError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
      </Form.Group>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check color="dark" type="checkbox" label="S'inscrire à la newsletter" {...register('subscribe_newsletter', { required: false })} />
          </Form.Group>
        </Col>
        <Col md={{ span: 4, offset: 2 }}>
          <Button variant="dark" type="submit" className="rounded-pill py-2 px-4">
            S&apos;inscrire
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
