import axios from 'axios'

export const Constants = {
  facebookLink: 'https://www.facebook.com/LesPoissonneriesParo/',
  instagramLink: 'https://www.instagram.com/romain.paro/',
  cartLocalStorageKey: 'cart',
  userLocalStorageKey: 'user'
}

export const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
  params: {
    display: 'full',
    output_format: 'JSON',
    // eslint-disable-next-line no-undef
    ws_key: process.env.REACT_APP_PRESTASHOP_API_KEY
  },
  headers: {
    Accept: 'application/json',
    // Authorization: `Basic ${Buffer.from(`${process.env.REACT_APP_PRESTASHOP_API_KEY}:`, 'utf8').toString('base64')}`
  },
  timeout: 15000
})
