import { Container, Row, Col } from 'react-bootstrap'

export default function NotFound() {
  return (
    <Container className="pb-6">
      <Row>
        <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
          <span className="text-dark" style={{ fontSize: '25.5vw' }}>
            404
          </span>
        </Col>
        <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
          <h4 className="text-dark text-center">
            La page que vous recherchez est introuvable.
          </h4>
        </Col>
      </Row>
    </Container>
  )
}
