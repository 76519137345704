import './Footer.scss'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import MenuItem from '@components/Menu/MenuItem'
import { useForm } from 'react-hook-form'
import { addGuestCustomer } from '../../Services/Customers'
import { isValidEmail } from '@components/Utils'
import { useState } from 'react'

export default function Footer({ footerRef }) {

  const { register, handleSubmit, reset } = useForm()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isInvalidEmail, setIsInvalidEmail ] = useState(false)

  const onSubmit = ({ email }) => {
    if (isValidEmail(email)) {
      setIsLoading(true)
      addGuestCustomer(email, undefined, undefined, true)
        .then(() => reset())
        .finally(() => {
          setIsLoading(false)
          setIsInvalidEmail(false)
        })
    } else {
      setIsInvalidEmail(true)
    }
  }

  return (
    <footer className="d-flex" ref={footerRef}>
      <Container fluid className="px-5 mt-8">
        <div className="h-100 d-flex flex-column justify-content-between">
          <Row className="menu-row px-3 d-flex align-items-center h-100">
            <Col lg={7} xs={12}>
              <MenuItem />
            </Col>
            <Col lg={5} xs={12}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    Newsletter
                  </Form.Label>
                  <InputGroup className="mb-3 newsletter-input">
                    <Form.Control
                      className="shadow-none text-footer"
                      placeholder="E-mail"
                      disabled={isLoading}
                      {...register('email', { required: true })}
                      isInvalid={isInvalidEmail}
                    />
                    <InputGroup.Append>
                      <Button variant="outline-secondary" className="text-footer" type="submit" disabled={isLoading}>
                        Envoyer
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="px-lg-3 pb-xl-6 pb-5">
            <Col lg={4} xs={12} className="text-lg-left text-center text-footer px-lg-4">
              <span>
                © PARO 2021
              </span>
            </Col>
            <Col lg={8} xs={12} className="text-lg-right text-center text-footer d-none d-md-block">
              <a href="/conditions" className="mr-5">
                Conditions générales de vente
              </a>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>

  )
}
