import { Col, Container, Row } from 'react-bootstrap'
import InstagramFeed from '@components/InstagramFeed/InstagramFeed'
import { toDate } from '../Utils'

export default function Contact({ shopOpeningHours }) {
  const address = '563 Route du Puy Sainte-Réparade, 13090 Aix-en-Provence'
  const phone = '04 42 22 69 09'
  const email = 'bienvenue@poissonnerieparo.fr'
  const openingHoursToText = () => {
    return shopOpeningHours
      .flatMap(_ => {
        return { ..._, days: _.days.map(day => day.format('dddd')) }
      }).flatMap(array => {
        let sentence = ''
        if (array.days.length > 2) {
          sentence = `Du ${ array.days[0] } au ${ array.days[array.days.length - 1] }: `
        } else {
          sentence = `Le ${ array.days[0] }: `
        }
        if (array.hours.morning) {
          sentence += `${ toDate(array.hours.morning[0]) } - ${ toDate(array.hours.morning[1]) }`
        }
        if (array.hours.afternoon) {
          sentence += ` / ${ toDate(array.hours.afternoon[0]) } - ${ toDate(array.hours.afternoon[1]) }`
        }
        if (array.hours.allDay) {
          sentence += `${ toDate(array.hours.allDay[0]) } - ${ toDate(array.hours.allDay[1]) }`
        }
        sentence += '.'
        return sentence
      })
  }
  return (
    <div>
      <Container>
        <Row className="py-6 px-3">
          <Col lg={4} xs={12}>
            <h6>
              Adresse
            </h6>
          </Col>
          <Col lg={8} xs={12} className="text-content">
            <p>
              {'Notre poissonnerie est implantée dans le centre marchand des commerces Aixois, au Nord d\'Aix-en-Provence, sur le site du Jardin des Sens, entre la Chocolaterie de Puyricard et le traiteur asiatique Zazaï.'}
            </p>
            <p>
              <a href="https://www.google.com/maps/place/PARO+Poissonnerie+et+Fruits+de+mer/@43.5635028,5.4324773,17z/data=!3m1!4b1!4m5!3m4!1s0x12c98d8820ec9151:0x14725d2a59bd2135!8m2!3d43.5635363!4d5.4346668" target="_blank" rel="noreferrer" className="text-dark">
                <b>
                  {address}
                </b>
              </a>
            </p>
          </Col>
          <Col lg={4} xs={12} className="pt-4 pt-lg-0">
            <h6>
              Téléphone
            </h6>
          </Col>
          <Col lg={8} xs={12} className="text-content">
            <div>
              <p className="mb-0">
                {'Nous sommes joignables sur les horaires d\'ouverture.'}
              </p>
            </div>
            { openingHoursToText().map((hour, index) => (
              <div key={index}>
                <p className={openingHoursToText().length - 1 === index ? '' : 'mb-0'}>
                  { hour }
                </p>
              </div>
            ))}
            <p>
              <a href={`tel:${phone}`} className="text-dark">
                <b>
                  {phone}
                </b>
              </a>
            </p>
          </Col>
          <Col lg={4} xs={12} className="pt-4 pt-lg-0">
            <h6>
              Email
            </h6>
          </Col>
          <Col lg={8} xs={12} className="text-content">
            <p>
              {'Vous pouvez adresser vos demandes par email.'}
            </p>
            <p>
              <a href={`mailto:${email}`} className="text-dark">
                <b>
                  {email}
                </b>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <InstagramFeed />
    </div>
  )
}
