import image1 from '@public/traiteur-de-la-mer-aix-en-provence.jpg'
import image2 from '@public/Plateau-coquillage-aix-en-provence.jpg'
import image3 from '@public/poisson-frais-aix-en-provence.jpg'
import image4 from '@public/plateau-plancha.jpg'
import image5 from '@public/denti-méditerranée.jpg'
import image6 from '@public/bar-de-ligne.jpg'
import image7 from '@public/conserve-kaviari.jpg'
import image8 from '@public/sashimi-aix-en-provence.jpg'
import image9 from '@public/sardine-poissonnerie.jpg'
import image10 from '@public/charcuterie-poisson.jpg'
import image11 from '@public/poissonnerie-aix-saucisse.jpg'
import image12 from '@public/brochette-de-joues-de-raie.jpg'
import image13 from '@public/maquereaux-de-ligne.jpg'
import image14 from '@public/fruits-de-mer-aix.jpg'
import image15 from '@public/gambas-carabineros.jpg'
import image16 from '@public/thon-germon-de-ligne.jpg'
import image17 from '@public/poisson-pageot.jpg'
import image18 from '@public/beaux-yeux.jpg'
import image19 from '@public/encornet-rouge-poissonnier.jpg'
import image20 from '@public/coquillage-fruits-de-mer.jpg'
import { Container, Col, Row } from 'react-bootstrap'

export default function About() {
  const images = [
    { img: image1, alt: 'Traiteur de la mer aix-en-provence' },
    { img: image2, alt: 'Plateau coquillage aix-en-provence' },
    { img: image3, alt: 'Poisson frais aix-en-provence' },
    { img: image4, alt: 'Réalisation de plateau plancha sur mesure poissonnerie aix' },
    { img: image5, alt: 'Poisson grosse taille grosse pièce gros denti de méditerranée poissonnerie aix' },
    { img: image6, alt: 'Acheter bar de ligne poissonnerie aix en provence' },
    { img: image7, alt: 'Conserve soupe de poisson conserve de sardine kaviari' },
    { img: image8, alt: 'Sashimi de poisson frais aix en provence' },
    { img: image9, alt: 'Sardine de méditerranée' },
    { img: image10, alt: 'Charcuterie de poisson' },
    { img: image11, alt: 'Saucisse de poisson poissonnerie' },
    { img: image12, alt: 'Brochette de joue de lotte poissonnerie aix en provence' },
    { img: image13, alt: 'Maquereaux de ligne' },
    { img: image14, alt: 'Plateaux de fruits de mer aix en provence' },
    { img: image15, alt: 'Crevette carabineros gambon écarlate Aristaeopsis edwardsiana' },
    { img: image16, alt: 'Thon de ligne thon blanc germon' },
    { img: image17, alt: 'Pageot de méditerranée poissonnerie' },
    { img: image18, alt: 'Poisson frais dorade beaux-yeux' },
    { img: image19, alt: 'Calmar calamar rouge encornet aluge de méditerranée' },
    { img: image20, alt: 'Plateaux de coquillages' },
  ]
  const image = (img, i) => <img key={i} src={img.img} alt={img.alt} width="100%" className="w-100 px-3 px-lg-0 pb-3 pb-lg-8" />
  return (
    <Container className="my-8">
      <Row>
        <Col sm={12} lg={6} className="px-3">
          {images.map((img, i) => i % 2 === 0 && image(img, i))}
        </Col>
        <Col sm={12} lg={6} className="px-3">
          {images.map((img, i) => i % 2 !== 0 && image(img, i))}
        </Col>
      </Row>
    </Container>
  )
}
