import { Container, Row, Col } from 'react-bootstrap'
import { IoChevronBack } from 'react-icons/all'
import { useEffect, useState } from 'react'
import { navigate } from 'hookrouter'
import moment from 'moment'
import { getOrder } from '@services/Orders'

export default function Confirmation({ reference }) {

  const [ order, setOrder ] = useState(undefined)

  useEffect(() => {
    getOrder(reference)
      .then(order => {
        if (order) {
          setOrder(order)
        } else {
          navigate('/404')
        }
      })
  }, [ ])

  return (
    <Container className="p-10">
      <Row>
        <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="text-dark font-weight-bold text-center" style={{ fontSize: '4rem' }}>
            Merci pour votre commande !
          </h2>
        </Col>
        <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
          <div className="text-dark text-center">
            <p>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Votre commande a bien été prise en compte et porte la référence <b>{order && order.reference}</b>.
            </p>
            <p>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Merci de venir la récupérer muni de votre numéro de référence {order && moment(order.date_add).format('dddd LL')} à {order && moment(order.date_add).format('HH:mm')}.
            </p>
          </div>
          <a className="text-secondary" href="/home">
            <IoChevronBack />
            Retourner sur le site
          </a>
        </Col>
      </Row>
    </Container>
  )
}
