import { Col, Container, Row } from 'react-bootstrap'
import { Constants } from '@components/Constants'
import { useEffect, useState } from 'react'

export default function InstagramFeed() {

  const [ instagramFeedMedias, setInstagramFeedMedias ] = useState([])
  const [ shouldHideComponent, setShouldHideComponent ] = useState(true)

  useEffect(() => {
    getInstagramUserFeed()
  }, [])

  const getInstagramUserFeed = () => {
    const headers = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    }
    const params = new URLSearchParams({
      fields: 'media_url,media_type,caption,permalink',
      // eslint-disable-next-line no-undef
      access_token: process.env.REACT_APP_INSTAGRAM_TOKEN,
    })
    // eslint-disable-next-line no-undef
    fetch(`https://graph.instagram.com/${process.env.REACT_APP_INSTAGRAM_USER_ID}/media?${params}`, { ...headers })
      .then(response => response.json())
      .then(response => response.data.filter(data => data.media_type === 'IMAGE'))
      .then(images => {
        setInstagramFeedMedias(images.slice(0, 6))
        setShouldHideComponent(false)
      })
      .catch(() => setShouldHideComponent(true))
  }

  return (
    <Container fluid className={shouldHideComponent ? 'd-none' : 'py-5'}>
      <Row className="px-3 px-lg-6">
        <Col xs={12}>
          <a href={Constants.instagramLink} className="text-dark">
            <h6>
              Instagram
            </h6>
          </a>
        </Col>
      </Row>
      <Row className="py-5 px-3 px-lg-6 align-items-center">
        {instagramFeedMedias.map(({ id, caption, media_url, permalink }) => (
          <Col xs={12} lg={2} md={6} className="py-lg-0 py-4" key={id}>
            <a href={permalink}>
              <img src={media_url} width="100%" alt={caption} />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
