export default function MenuItem() {
  return (
    <ul className="menu-list">
      <li className="py-3">
        <span>
          <a href="/about" className="underline pb-2">
            La poissonerie
          </a>
        </span>
      </li>
      <li className="py-3">
        <span>
          <a href="/contact" className="underline pb-2">
            Nous contacter
          </a>
        </span>
      </li>
      <li className="py-3">
        <span>
          <a href="/shop" className="underline pb-2">
            Commander en ligne
          </a>
        </span>
      </li>
    </ul>
  )
}
