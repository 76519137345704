import { api } from '@components/Constants'
import { cartTotalPrice } from '@components/Utils'
import { toXML } from 'jstoxml/jstoxml'

export const createCart = async (addressId, cart, user) => {
  const emptyCart = await api.get('/carts?schema=blank')
    .then(res => res.data.carts[0])
  const cartRows = cart.map(product => ({ cart_row: { id_product: product.id, id_product_attribute: product.id_product_attribute, quantity: product.qty, id_adddress_delivery: addressId } }))
  const newCart = {
    prestashop: {
      cart: {
        ...emptyCart,
        id_address_delivery: addressId,
        id_address_invoice: addressId,
        id_customer: user.id,
        id_currency: 1, // EUR ???
        id_lang: 1, // FR ???
        id_shop: 1,
        id_shop_group: 1,
        id_carrier: 1,
        associations: {
          cart_rows: cartRows
        }
      }
    }
  }

  return api.post('/carts', toXML(newCart))
    .then(res => res.data.carts[0].id)
}

export const createOrder = async (addressId, cartId, cart, user, day, hour) => {
  const emptyOrder = await api.get('/orders?schema=blank')
    .then(res => res.data.orders[0])
  const orderRows = cart.map(product => ({ order_row: { id_product: product.id, id_product_attribute: product.id_product_attribute, quantity: product.qty, id_adddress_delivery: addressId } }))
  const newOrder = {
    prestashop: {
      order: {
        ...emptyOrder,
        valid: 1,
        id_address_delivery: addressId,
        id_address_invoice: addressId,
        id_cart: cartId,
        id_currency: 1, // EUR
        id_lang: 1, // FR
        id_customer: user.id,
        id_carrier: 1,
        id_shop: 1,
        current_state: 1,
        id_shop_group: 1,
        module: 'ps_cashondelivery',
        payment: 'Paiement en espèce',
        total_paid: cartTotalPrice(cart),
        total_paid_real: cartTotalPrice(cart),
        total_shipping: 0,
        total_products: 1,
        total_products_wt: 1,
        conversion_rate: 1,
        delivery_date: `${day.format('YYYY-MM-DD')} ${hour}:00`,

        associations: {
          order_rows: orderRows
        }
      }
    }
  }
  return api.post('/orders', toXML(newOrder))
    .then(res => res.data.orders[0])
}

export const updateOrder = async (orderId, day, hour) => {
  const order = await api.get(`/orders/${orderId}`, { params: { ...api.params, display: undefined } })
    .then(res => res.data.order)
  const formatedDate = `${day.format('YYYY-MM-DD')} ${hour}:00`
  const updatedOrder = {
    orders: [
      {
        element: {
          ...order,
          date_add: formatedDate,
          delivery_date: formatedDate,
          invoice_date: formatedDate
        }
      }
    ]
  }
  return api.put(`/orders/${order.id}`, toXML(updatedOrder))
    .then(res => res.data.orders[0])
}

export const isOrderSuccess = async (orderId) => {
  const orderStatus = await api.get(`/order_histories?filter[id_order]=${orderId}`)
    .then(res => res.data.order_histories[0])
  return orderStatus && orderStatus.id_order_state !== '8'
}

export const getOrder = async (reference) => {
  const order = await api.get(`/orders?filter[reference]=${reference}`)
    .then(res => res.data.orders[0])
  if (order) {
    return order
  } else {
    throw 'unknown order'
  }
}
