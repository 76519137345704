import { Container } from 'react-bootstrap'

export default function Conditions() {
  const articles = [
    {
      title: 'ARTICLE 1 - APPLICATION DES CONDITIONS - AIX',
      content: `
        Le vendeur est défini ci-dessous comme étant SAS PARO, dont le siège social se trouve à l'adresse suivante :
        PARO
        27 ROUTE DE SALON 
        13330 PELISSANNE 
        TVA : FR91811961192
        Société inscrite au Registre du Commerce et des sociétés de Salon de Provence (13) sous le numéro 811961192 et l'acheteur est défini ci-dessous comme étant la société ou la personne signataire et acceptant les présentes conditions de vente. L'acheteur reconnaît avoir la capacité de contracter aux conditions décrites ci-après, c'est à dire avoir la majorité légale et ne pas être sous tutelle ou curatelle. L'acheteur reconnaît avoir pris connaissance des présentes CGV avant d'avoir passé la commande. Les conditions générales de vente s'appliquent de façon exclusive à toutes les ventes conclues par la société PARO, tant pour son propre compte que pour celui de ses commettants. Toutes autres conditions n'engagent le vendeur qu'après confirmation écrite de sa part. Le seul fait de passer une commande ou d'accepter une offre du vendeur comporte l'acceptation sans réserve des présentes conditions générales. Toute condition non conforme à nos conditions générales que l'acheteur aura formulée sera rejetée à moins que nous n'en ayons expressément et par écrit reconnu l'applicabilité. Les présentes conditions générales peuvent être modifiées à tout moment et sans préavis par la société SAS PARO, les modifications étant alors applicables à toutes commandes postérieures.
      `
    },
    {
      title: 'ARTICLE 2 - COMMANDES',
      content: `
        Les commandes sont effectives dès la date de réception du règlement.
        Toute offre de vente s'entend sous réserve des stocks disponibles. Néanmoins, si en dépit de la vigilance du vendeur concernant ses stocks, les produits s'avéraient indisponibles après la commande de l'acheteur, la société PARO s'engage à vous en informer dans les meilleurs délais, et pourra vous permettre d'annuler ou de modifier votre commande.
        Dans le cas où le paiement de la commande de l'acheteur aurait déjà été débité par le vendeur, et qu'aucune modification de la dite commande ne semble être possible, l'acheteur sera remboursé sans délais et au plus tard dans les 30 jours du paiement des sommes qu'il a versé.
        Toute clause émanant de l'acheteur, non acceptée par écrit par le vendeur qui serait en opposition avec les présentes conditions générales ou particularités définies dans l'offre de prix sera considérée comme nulle. Aucune annulation unilatérale de commande ne sera acceptée sans l'accord de la société PARO.
      `
    },
    {
      title: 'ARTICLE 3 - PRIX',
      content: `
        Tous les prix indiqués sur le site Internet du vendeur s'entendent nets en euros, toutes taxes comprises, hors frais de transport, qui seront indiqués avant l'enregistrement de la commande par l'Acheteur.
      `
    },
    {
      title: 'ARTICLE 4 - PAIEMENT',
      content: `
        Toutes les commandes sont payables en Euros. Le paiement de la totalité du prix doit être réalisé lors de la commande par l'Acheteur. A aucun moment, les sommes versées ne pourront être considérées comme des arrhes ou acomptes. Le paiement intervient à la commande ou au plus tard à la livraison ou au moment de l'enlèvement sur place. Aucune commande ne pourra être libérée sans être payée. Pour régler sa commande, l'Acheteur dispose de plusieurs moyens de règlement : sur place ESPECES, CB, CHEQUE, en ligne en CB.
        L'acheteur garantit à la société PARO qu'il dispose des autorisations éventuellement nécessaires pour utiliser le mode de paiement qu'il aura choisi, lors de l'enregistrement du bon de commande.
        La société PARO se réserve le droit de suspendre ou d'annuler toute commande et/ou livraison, quels que soient leur nature et niveau d'exécution, en cas de non-paiement de toute somme qui serait due par l'Acheteur ou en cas d'incident de paiement.
        La société PARO se réserve le droit de demander une photocopie de la carte d'identité de l'acheteur et/ou d'un justificatif de domicile pour toute commande.
        Dans le cadre de la lutte contre les fraudes sur Internet, les informations relatives à votre commande pourront être contrôlées pour vérification.
      `
    },
    {
      title: 'ARTICLE 5 - CHOIX DES ARTICLES',
      content: `
        L'acheteur ayant pris connaissance des produits et de leurs caractéristiques, commercialisés par la PARO, a sous sa seule responsabilité et en fonction de ses besoins tels qu'ils les a préalablement déterminés avant toute commande, porté son choix sur le ou les produits faisant l'objet de sa commande.
        Voir nos informations techniques.
      `
    },
    {
      title: 'ARTICLE 6 - RESERVE DE PROPRIETE',
      content: `
        Conformément à la loi, le transfert de propriété des marchandises livrées à l'acheteur n'interviendra qu'après paiement intégral du prix en principal et accessoire ou l'encaissement des chèques acceptés ou d'autres moyens de paiement émis aux fins de règlement du prix.
        Durant la période s'écoulant de la livraison au transfert de propriété, les risques de perte, vol ou destruction sont à la charge de l'acheteur. L'inexécution par l'acheteur de ses obligations de paiement, pour quelque cause que ce soit, confère au vendeur le droit d'exiger la restitution immédiate des marchandises livrées aux frais, risques et périls de l'acheteur.
      `
    },
    {
      title: 'ARTICLE 7 - LOI APPLICABLE ET CLAUSE DE JURIDICTION',
      content: `
        Le présent contrat est soumis à la loi française. En cas de litige, le tribunal du lieu de livraison sera seul compétent.
      `
    },
    {
      title: 'ARTICLE 8 – DROIT DE RÉTRACTATION',
      content: `
        Conformément à la loi et notamment l’article L 121-21-8, le droit de rétractation ne  peut être exercé dans le cas de « fourniture de biens susceptibles de se détériorer ou de se périmer rapidement ».  Aussi en l’absence de problèmes constatés par le Client à la livraison et évoqués à l’article 6 des Présentes, le droit de rétractation ne peut être exercé.
        Concernant les denrées non périssables vendues sur le site www.toinou.com le droit de rétractation s'exerce selon les conditions suivantes : que l'article à reprendre soit intact et qu'il n'ait pas été entamé/ouvert, et la reprise ne peut se faire qu'après en avoir expressément informé le livreur au moment de la livraison. En l'absence d'anomalie et après le départ du livreur aucun article non périssable ne sera repris.
      `
    },
    {
      title: 'ARTICLE 9 - DISPOSITION INFORMATIQUES ET LIBERTES',
      content: `
        Conformément à la loi Informatique et Libertés du 6 Janvier 1978, vous disposez d'un droit d'accès et de rectification aux données personnelles vous concernant.
      `
    }
  ]
  return (
    <Container className="py-10" style={{ whiteSpace: 'pre-line' }}>
      { articles.map((article, index) => (
        <article key={index}>
          <h2>
            {article.title}
          </h2>
          <p>
            {article.content}
          </p>
        </article>
      ))}
    </Container>
  )
}
