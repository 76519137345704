import './Menu.scss'
import Footer from '@components/Footer/Footer'

export default function Menu({ style }) {
  return (
    <div style={style} className="menu-dialog">
      <Footer />
    </div>
  )
}
