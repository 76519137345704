import { Container, Table, Form } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/all'
import './Cart.scss'
import { cartTotalPrice, QuantityInput } from '@components/Utils'

export default function Cart({ cart, updateQuantity, removeProductFromCart }) {
  return (
    <Container className="py-10">
      <h4>
        Panier
      </h4>
      { cart.length > 0 ?
        <Table borderless>
          <thead>
            <tr>
              <th colSpan="3" className="pl-0">
                Produit
              </th>
              <th className="text-center">
                Qté
              </th>
              <th className="text-right">
                Prix
              </th>
            </tr>
          </thead>
          <tbody>
            { cart.map(product => (
              <tr className="col-borders cart-table-text" key={product.id}>
                <td className="p-0 align-middle" width="24">
                  <button className="btn btn-link text-lightgray p-0" onClick={() => removeProductFromCart(product)}>
                    <IoMdClose size={20} />
                  </button>
                </td>
                <td width="100" className="align-middle">
                  {/* eslint-disable-next-line no-undef */}
                  <img src={product.image_url} alt="" className="cart-product-image"/>
                </td>
                <td className="text-left align-middle">
                  {product.name}
                </td>
                <td className="text-center align-middle" width="80">
                  <Form.Group className="mb-0">
                    <QuantityInput product={product} updateQuantity={updateQuantity} />
                  </Form.Group>
                </td>
                <td className="text-right align-middle" width="100">
                  {(Number(product.priceTTC) * product.qty).toFixed(2)}
                  {' '}
                  €
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="5" className="text-right">
                <span className="mr-2 text-content">
                  Total
                </span>
                <span>
                  { cartTotalPrice(cart) }
                  {' '}
                  €
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan="5" className="text-right">
                <a href="/shop" className="btn btn-outline-dark mr-3">
                  Revenir
                </a>
                <a href="/checkout" className="btn btn-dark">
                  Continuer
                </a>
              </td>
            </tr>
          </tfoot>
        </Table>
        :
        <div>
          <p className="text-content">
            Votre panier est vide.
          </p>
          <a href="/shop" className="text-dark">
            Poursuivre vos achats.
          </a>
        </div>
      }
    </Container>
  )
}
