import { Form } from 'react-bootstrap'

export const cartTotalPrice = (cart) =>
  cart.reduce((acc, product) => acc + (Number(product.priceTTC) * product.qty), 0).toFixed(2)

export const totalProductQty = (cart) =>
  cart.reduce((acc, product) => acc + product.qty, 0)

export const isValidEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export const QuantityInput = ({ product, updateQuantity }) => {

  const onKeyPress = (event) => {
    !/[0-9]/.test(event.key) && event.preventDefault()
  }

  const onChange = (event) => {
    updateQuantity(product, Number(event.target.value !== '' ? event.target.value : 1))
  }

  const onBlur = (event) => {
    if (event.target.value === '' || event.target.value === '0') {
      event.target.value = '1'
    }
  }

  return (
    <Form.Control size="sm" type="text" defaultValue={product.qty} onKeyPress={onKeyPress} onChange={onChange} onBlur={onBlur} />
  )
}

export const toDate = (number) => {
  switch (number.length) {
  case 1:
    return `0${number}:00`
  case 2:
    return `${number}:00`
  default:
    return number
  }
}

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  enumerable: false
})
