import { api } from '@components/Constants'
import { toXML } from 'jstoxml/jstoxml'
import bcrypt from 'bcryptjs'

export const login = async (email, password) => {
  const user = await getUserByEmail(email)
  if (user) {
    const isValidPassword = bcrypt.compareSync(password, user.passwd)
    if (isValidPassword) {
      return user
    } else {
      throw 'bad-password-error'
    }
  } else {
    throw 'bad-email-error'
  }
}

export const signUp = async (email, passwd, firstname, lastname, phone, subscribe_newsletter) => {
  const user = await getUserByEmail(email)
  if (user) {
    throw 'email-already-exist'
  } else {
    const emptyCustomer = await api.get('/customers?schema=blank')
      .then(res => res.data.customers[0])
    const newCustomer = {
      prestashop: {
        customer: {
          ...emptyCustomer,
          email,
          lastname,
          firstname,
          newsletter: subscribe_newsletter ? '1' : emptyCustomer.newsletter,
          optin: subscribe_newsletter ? '1' : emptyCustomer.optin,
          passwd,
          active: '1',
          note: 'N/A'
        }
      }
    }
    return api.post('/customers', toXML(newCustomer))
      .then(res => res.data.customers[0])
  }
}

const getUserByEmail = async (email) => {
  const customers = await api.get(`/customers?filter[email]=${email}`)
    .then(res => res.data.customers)
  if (customers && customers.length > 0) {
    return customers[0]
  } else {
    return undefined
  }
}

export const addGuestCustomer = async (email, firstname, lastname, shouldSubscribeToNewsletter) => {
  const customersWithSameEmail = await api.get(`/customers?filter[email]=${email}`)
    .then(res => res.data.customers ?? [])
  if (customersWithSameEmail.length > 0) {
    return customersWithSameEmail[0].id
  } else {
    const emptyCustomer = await api.get('/customers?schema=blank')
      .then(res => res.data.customers[0])
    const newCustomer = {
      prestashop: {
        customer: {
          ...emptyCustomer,
          email,
          lastname: lastname || 'N/A',
          firstname: firstname || 'N/A',
          newsletter: shouldSubscribeToNewsletter ? '1' : emptyCustomer.newsletter,
          optin: shouldSubscribeToNewsletter ? '1' : emptyCustomer.optin,
          passwd: 'mypassword',
          note: 'Customer created with api'
        }
      }
    }
    return api.post('/customers', toXML(newCustomer))
      .then(res => res.data.customers[0].id)
  }
}
