import './Shop.scss'
import Product from '@components/Shop/Product'
import placeholder from '@public/fish_placeholder.png'
import { Col, Container, Row, Card, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { api } from '../Constants'
import { IoChevronForward } from 'react-icons/all'
import { cartTotalPrice } from '../Utils'

export default function Shop({ link, setIsLoading, addProductToCart, cart, removeProductFromCart }) {
  const [ selectedCategory, setSelectedCategory ] = useState()
  const [ allProducts, setAllProducts ] = useState([])
  const [ taxes, setTaxes ] = useState([])
  const [ products, setProducts ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ combinations, setCombinations ] = useState([])

  useEffect(() => {
    const getTaxes = () => {
      api.get('/taxes')
        .then(response => setTaxes(response.data.taxes))
    }
    getTaxes()
  }, [])
  
  const groupBy = (items, key) => items && Object.values(items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  ))
  
  useEffect(() => {
    const getProducts = async (productOptions) => {
      await api.get('/products')
        .then(response => response.data.products)
        .then(products => (products.map(p => {
          const variants = groupBy(p.associations
              && p.associations.product_option_values
              && p.associations.product_option_values.map(product_option_value => {
                return product_option_value ? productOptions.filter(_ => _.id === Number(product_option_value.id))[0] : []
              }), 'id_attribute_group')
          const taxe = taxes.find(t => t.id === Number(p.id_tax_rules_group))
          const vatAmount = Number(p.price) * Number(taxe.rate) / 100
          return { 
            ...p,
            taxeRate: taxe.rate,
            priceTTC: Number(Number(p.price) + vatAmount), 
            image_url: p.id_default_image 
              // eslint-disable-next-line no-undef
              ? `${process.env.REACT_APP_API_BASE_URL}/images/products/${p.id}/${p.id_default_image}?ws_key=${process.env.REACT_APP_PRESTASHOP_API_KEY}`
              : placeholder,
            variants: variants || []
          }
        })))
        .then(products => {
          setAllProducts(products)
          setProducts(products)
        })
    }
    const getCategories = async () => {
      await api.get('/categories')
        .then(response => response.data.categories
          .filter(categorie => (!categorie.associations) || categorie.associations.products !== undefined && categorie.associations.categories === undefined && categorie.active === '1')
        )
        .then(cat => {
          setCategories(cat)
          return cat
        })
        .then((cat) => setSelectedCategory(cat[0]))
    }
    const getProductOption = async () => {
      return api.get('/product_option_values')
        .then(response => response.data.product_option_values)
    }
    const getCombinations = async () => {
      await api.get('/combinations')
        .then(response => setCombinations(response.data.combinations))
    }
    if (taxes.length > 0) {
      setIsLoading(true)
      getCategories()
        .then(() => getCombinations())
        .then(() => getProductOption())
        .then((productOptions) => getProducts(productOptions))
        .then(() => setIsLoading(false))
    }
  }, [ taxes, setIsLoading ])

  useEffect(() => {
    if (selectedCategory) {
      setProducts(allProducts.filter(product => product.id_category_default === `${selectedCategory.id}`))
    } else {
      setProducts(allProducts)
    }
  }, [ selectedCategory, allProducts ])

  return (
    <div className="py-6">
      <Container className="d-flex justify-content-center align-items-center">
        <div className="d-flex align-items-center">
          { categories.map(category => (
            <button key={category.id} className={`btn px-0 mx-3 ${(selectedCategory && selectedCategory.id === category.id) ? 'button-underline' : 'underline'}`} onClick={() => setSelectedCategory(category)}>
              {category.name}
            </button>
          ))}
        </div>
      </Container>

      <Container className="my-5">
        <Row>
          <Col lg={cart.length > 0 ? 9 : 12}>
            <Row>
              {products.map((product, index) => {
                return <Product key={index} link={link} product={product} addProductToCart={addProductToCart} combinations={combinations} />
              })}
            </Row>
          </Col>
          <Col lg={3} className={cart.length === 0 && 'd-none'} >
            <Card className="sticky-top mt-4 mt-lg-0" style={{ top: '122px', zIndex: 1000 }}>
              <Card.Body>
                <h5>
                  Commande en cours
                </h5>
                <Table borderless className="text-small mb-0">
                  <tbody>
                    { cart.map((product, index) => (
                      <tr className="cart-table-text" key={index}>
                        <td className="text-left p-1">
                          <b>
                            {product.qty}
                            x
                          </b> 
                          {' '}
                          {product.name}
                        </td>
                        <td className="text-right p-1">
                          <span>
                            <b>
                              {(Number(product.priceTTC) * product.qty).toFixed(2)}
                              €
                            </b>
                          </span>
                          <br/>
                          <button className="btn btn-link btn-sm text-secondary p-0" onClick={() => removeProductFromCart(product)}>
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-left px-1">
                        <span>
                          <b>
                            Total
                          </b>
                        </span>
                      </td>
                      <td className="text-right px-1">
                        <span>
                          <b>
                            { cartTotalPrice(cart) }
                            €
                          </b>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-right px-1">
                        <a className="text-secondary" href="/cart">
                          Aller au panier
                          <IoChevronForward />
                        </a>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
