import './Checkout.scss'
import { Container, Row, Col, Card, Jumbotron, Form, Table, InputGroup, Button } from 'react-bootstrap'
import { HiChevronRight, HiChevronLeft } from 'react-icons/all'
import { useEffect, useState } from 'react'
import { navigate } from 'hookrouter'
import { useForm } from 'react-hook-form'
import { cartTotalPrice, QuantityInput } from '@components/Utils'
import { createCart, createOrder, updateOrder, isOrderSuccess } from '@services/Orders'
import moment from 'moment'

export default function Checkout({ user, setIsLoading, cart, updateQuantity, removeProductFromCart, clearCart, setOnContentChange, shopOpeningHours }) {
  const [ isFirstLoad, setIsFirstLoad ] = useState(true)
  const [ firstStepComplete, setFirstStepComplete ] = useState(false)
  const [ secondStepComplete, setSecondStepComplete ] = useState(false)
  const [ availableDays, setAvailableDays ] = useState([])
  const [ selectedDay, setSelectedDay ] = useState(undefined)
  const [ availableHours, setAvailableHours ] = useState(undefined)
  const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState(undefined)
  const { handleSubmit: handleSubmit1, register: register1, getValues: getValues1 } = useForm()
  const { handleSubmit: handleSubmit2 } = useForm()
  const { handleSubmit: handleSubmit3 } = useForm()

  const paymentMethods = [
    { key: 'on_delivery', value: 'Sur place' }
  ]

  const currentDay = { original: moment(), formatted: moment().format(moment.HTML5_FMT.DATE) }

  useEffect(() => {
    if (!user && !isFirstLoad) {
      navigate('/login', true)
    } else {
      if (!isFirstLoad && cart.length === 0) {
        navigate('/cart')
      }
      setIsFirstLoad(false)
      setOnContentChange({})
    }
  }, [ user, cart, isFirstLoad, setOnContentChange ])

  useEffect(() => {
    setOnContentChange({})
  }, [ firstStepComplete, secondStepComplete, setOnContentChange ])

  useEffect(() => {
    setAvailableDays(
      shopOpeningHours
        .flatMap(_ => _.days)
    )
    setSelectedPaymentMethod(paymentMethods[0])
  }, [ ])

  useEffect(() => {
    if (!selectedDay) {
      setSelectedDay(currentDay)
    }
  }, [ availableDays ])

  useEffect(() => {
    if (selectedDay) {
      setAvailableHours(
        shopOpeningHours
          .filter(_ => _.days.map(_ => _.isoWeekday()).includes(selectedDay.original.isoWeekday()))
          .reduce((acc, day) => {
            const append = (begin, end) => {
              for (let i = begin + 1; i < end; i++) {
                acc.push(i)
              }
            }
            if (day.hours.morning) {
              append(Number(day.hours.morning[0]), Number(day.hours.morning[1]))
            }

            if (day.hours.afternoon) {
              const isHalfAnHour = day.hours.afternoon[0].split(':')
              if (isHalfAnHour.length > 0) {
                append(Number(isHalfAnHour[0]), Number(day.hours.afternoon[1]))
              } else {
                append(Number(day.hours.afternoon[0]), Number(day.hours.afternoon[1]))
              }
            }

            if (day.hours.allDay) {
              append(Number(day.hours.allDay[0]), Number(day.hours.allDay[1]))
            }
            return acc
          }, [])
      )
    }
  }, [ selectedDay ])

  const goNextWeek = () => {
    setAvailableDays(
      availableDays
        .map(_ => _.add(7, 'days'))
    )
  }

  const goPreviousWeek = () => {
    setAvailableDays(
      availableDays
        .map(_ => _.subtract(7, 'days'))
    )
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      const addressId = 1
      const cartId = await createCart(addressId, cart, user)
      const order = await createOrder(addressId, cartId, cart, user, selectedDay.original, getValues1('hour'))
      const updatedOrder = await updateOrder(order.id, selectedDay.original, getValues1('hour'))
      const isSuccess = await isOrderSuccess(order.id)
      setIsLoading(false)
      if (isSuccess) {
        navigate(`/checkout/confirmation/${updatedOrder.reference}`, true)
        clearCart()
      } else {
        alert('Une erreur est survenue lors de votre commande, veuillez réessayer plus tard ou contacter la poissonnerie.')
      }
    } catch {
      setIsLoading(false)
      alert('Une erreur est survenue lors de votre commande, veuillez réessayer plus tard ou contacter la poissonnerie.')
    }
  }

  return (
    <Jumbotron className="m-0">
      <Container>
        <Row>
          <Col sm={12} lg={6}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <h5>
                    1. Jour et heure de retrait
                  </h5>
                  {firstStepComplete && <Button variant="link" className="text-secondary" onClick={() => setFirstStepComplete(false)}>
                    Modifier
                  </Button>}
                </div>
                {firstStepComplete ?
                  <div className="text-secondary">
                    <p>
                      { `${selectedDay.original.format('dddd LL').capitalize()} à ${getValues1('hour')}` }
                    </p>
                  </div>
                  :
                  <Form className="mt-2" onSubmit={handleSubmit1(() => setFirstStepComplete(true))}>
                    <Form.Group>
                      <Form.Label>
                        Sélectionner un jour
                      </Form.Label>
                      <Row>
                        { availableDays
                          .map((day, i) => (
                            <Col xs={6} md={4} key={i} className="mb-4">
                              <div className={`d-flex text-center flex-column form-group-aspect ${currentDay.formatted > day.format(moment.HTML5_FMT.DATE) ? 'disable' : ''} ${selectedDay && selectedDay.formatted === day.format(moment.HTML5_FMT.DATE) ? 'active' : ''}`} onClick={() => currentDay.formatted <= day.format(moment.HTML5_FMT.DATE) && (setSelectedDay({ original: day, formatted: day.format(moment.HTML5_FMT.DATE) }))}>
                                <div>
                                  { day.format('dddd').capitalize() }
                                </div>
                                <div>
                                  { day.format('DD') }
                                </div>
                                <div>
                                  { `${day.format('MMMM').capitalize()} ${day.format('YYYY')}` }
                                </div>
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                      <div className="previous-next-buttons">
                        <Button variant="link" className="text-dark" disabled={selectedDay && currentDay.formatted > availableDays[0].format(moment.HTML5_FMT.DATE)} onClick={() => goPreviousWeek()}>
                          <HiChevronLeft size={30} />
                        </Button>
                        <Button variant="link" className="text-dark" onClick={() => goNextWeek()}>
                          <HiChevronRight size={30} />
                        </Button>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Sélectionner une heure
                      </Form.Label>
                      <Form.Control as="select" {...register1('hour', { required: true })}>
                        { availableHours && availableHours
                          .map((hour, index) => (
                            <option key={index}>
                              { `${hour}:00` }
                            </option>
                          ))
                        }
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Button variant="dark" type="submit" className="w-100">
                        Continuer
                      </Button>
                    </Form.Group>
                  </Form>
                }

              </Card.Body>
            </Card>
            <Card className="mt-4">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <h5 className={`mb-0 ${firstStepComplete ? '' : 'text-secondary'}`}>
                    2. Règlement
                  </h5>
                  {secondStepComplete && <Button variant="link" className="text-secondary" onClick={() => setSecondStepComplete(false)}>
                    Modifier
                  </Button>}
                </div>
                {secondStepComplete ?
                  <div className="text-secondary">
                    <h5>
                      Type de règlement
                    </h5>
                    <p>
                      { selectedPaymentMethod.value }
                    </p>
                  </div>
                  :
                  <Form className={firstStepComplete ? '' : 'd-none'} onSubmit={handleSubmit2(() => setSecondStepComplete(true))}>
                    <div className="py-3">
                      <Form.Group>
                        <Row>
                          { paymentMethods.map((paymentMethod, i) => (
                            <Col xs={6} key={i}>
                              <div className={`p-3 d-flex align-items-center flex-column form-group-aspect ${selectedPaymentMethod && selectedPaymentMethod.key === paymentMethod.key ? 'active' : ''}`} onClick={() => (setSelectedPaymentMethod(paymentMethod))}>
                                <div className="mb-2">
                                  { paymentMethod.value }
                                </div>
                                <div className={`radio-border ${selectedPaymentMethod && selectedPaymentMethod.key === paymentMethod.key ? 'active' : ''}`}>
                                  <div className="radio-center" />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <Form.Text className={`text-muted ${selectedPaymentMethod && selectedPaymentMethod.key === 'on_delivery' ? 'visible' : 'd-none'}`}>
                          Règlement sur place à la poissonnerie lors du retrait de la commande.
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <Form.Group>
                      <Button variant="dark" className="w-100" type="submit">
                        Continuer
                      </Button>
                    </Form.Group>
                  </Form>
                }

              </Card.Body>
            </Card>
            <Card className="mt-4">
              <Card.Body>
                <h5 className={`mb-0 ${secondStepComplete ? '' : 'text-secondary'}`}>
                  3. Vérifier et commander
                </h5>
                <Form onSubmit={handleSubmit3(onSubmit)}>
                  <Form.Group className={secondStepComplete ? '' : 'd-none'}>
                    <div className="py-3">
                      <p className="text-muted">
                        Vérifiez les informations saisies avant de commander.
                      </p>
                      <Form.Check type="checkbox" id="conditions">
                        <Form.Check.Input type="checkbox" required name="terms" />
                        <Form.Check.Label>
                          <Form.Text muted>
                            En cochant cette case, vous acceptez les&nbsp;
                            <a href="/conditions" className="text-dark">
                              condtions générales de vente
                            </a>
                            .
                          </Form.Text>
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <Button variant="dark" type="submit" className="w-100">
                      Commander
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} lg={6}>
            <Card className="sticky-top mt-4 mt-lg-0" style={{ top: '20px', zIndex: 0 }}>
              <Card.Body>
                <h5>
                  Résumé de la commande
                </h5>
                <Table borderless>
                  <tbody>
                    { cart.map(product => (
                      <tr className="cart-table-text" key={product.id}>
                        <td width="100">
                          {/* eslint-disable-next-line no-undef */}
                          <img src={product.image_url} alt="" className="checkout-product-image"/>
                        </td>
                        <td className="text-left">
                          {product.name}
                        </td>
                        <td className="text-right" width="120">
                          <span>
                            <b>
                              {(Number(product.priceTTC) * product.qty).toFixed(2)}
                              {' '}
                              €
                            </b>
                          </span>
                          <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                            Username
                          </Form.Label>
                          <InputGroup size="sm" className="mb-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                qté
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <QuantityInput product={product} updateQuantity={updateQuantity} />
                          </InputGroup>
                          <button className="btn btn-link btn-sm text-secondary p-0" onClick={() => removeProductFromCart(product)}>
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2" className="text-left">
                        <span>
                          <b>
                            Total
                          </b>
                        </span>
                      </td>
                      <td colSpan="2" className="text-right">
                        <span>
                          <b>
                            { cartTotalPrice(cart) }
                            {' '}
                            €
                          </b>
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}
