import { navigate } from 'hookrouter'
import { Container, Row, Col, Modal, Form, Button, InputGroup } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

export default function ProductModal({ product, showModal, setShowModal, addProductToCart, combinations }) {

  const [ quantity, setQuantity ] = useState(1)
  const { handleSubmit, register, getValues } = useForm()

  useEffect(() => {
    setQuantity(1)
  }, [ showModal ])

  const hide = () => {
    navigate('/shop')
    setShowModal(false)
  }

  const addToCart = () => {
    const additionalName = Object.values(getValues()).reduce((previous, current, index) => {
      if (index > 0) {
        previous += ` - ${JSON.parse(current).name}`
      } else {
        previous += `${JSON.parse(current).name}`
      }
      return previous
    }, '')
    const combination = combinations.filter(c => {
      if (product.id === Number(c.id_product)) {
        const array = c.associations.product_option_values.flatMap(_ => Number(_.id)).sort((a, b) => a > b)
        const ids = Object.values(getValues()).map(value => JSON.parse(value).id).sort((a, b) => a > b)
        return JSON.stringify(ids) === JSON.stringify(array)
      } else {
        return false
      }
    })
    if (combination.length > 0) {
      const vatAmount = Number(combination[0].price) * Number(product.taxeRate) / 100
      const newPriceTTC = product.priceTTC + Number(Number(combination[0].price) + vatAmount)
      addProductToCart({ ...product, priceTTC: newPriceTTC, name: `${product.name} (${additionalName})`, id_product_attribute: combination[0].id }, quantity)
    } else {
      addProductToCart(product, quantity)
    }
    hide()
  }

  const quantityInputOnKeyPress = (event) => {
    !/[0-9]/.test(event.key) && event.preventDefault()
  }

  const quantityInputOnChange = (event) => {
    setQuantity(Number(event.target.value !== '' ? event.target.value : 1))
  }

  const quantityInputOnBlur = (event) => {
    if (event.target.value === '' || event.target.value === '0') {
      event.target.value = '1'
    }
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={hide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          { product.name }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <img src={product.image_url} alt="" className="w-100 product-image" />
            </Col>
            <Col>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
              <Form onSubmit={handleSubmit(addToCart)}>
                <Row>
                  { product.variants && product.variants.map((variant, index) => (
                    <Col key={index}>
                      <Form.Group>
                        <Form.Control as="select" id="select1" {...register(`select${index}`, { required: true })}>
                          { variant.map((opt, index) => (
                            <option value={JSON.stringify({ id: opt.id, name: opt.name })} key={index}>
                              { opt.name }
                            </option>
                          )) }
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  )) }
                  <Col>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            qté
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control id="quantity" type="text" defaultValue="1" onKeyPress={quantityInputOnKeyPress} onChange={quantityInputOnChange} onBlur={quantityInputOnBlur} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <Form.Group>
                      <Button type="submit" variant="dark" className="rounded-pill py-2 px-4">
                        Ajouter
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
