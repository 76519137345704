import './Navigation.scss'
import { Constants } from '@components/Constants'
import { Container, Navbar, Nav, Button } from 'react-bootstrap'
import { IoBagOutline, RiFacebookLine, CgInstagram, HiOutlineMenuAlt4, IoMdClose } from 'react-icons/all'


export default function Navigation({ isInFooter, isMenuOpen, setIsMenuOpen, cartLength }) {
  return (
    <Navbar sticky="top" className={(isInFooter || isMenuOpen) && 'in-footer'}>
      <Container fluid className="px-3 px-lg-6 pt-6 pt-lg-6">
        <Navbar.Brand href="/" className="logo-text underline">
          <h1 className="h5 font-weight-normal">
            PARO
          </h1>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link href={Constants.instagramLink}>
              <CgInstagram size={20} />
            </Nav.Link>
            <Nav.Link href={Constants.facebookLink}>
              <RiFacebookLine size={20} />
            </Nav.Link>
            <Nav.Link href="/cart">
              <IoBagOutline size={20} />
              <span className="badge badge-pill badge-danger text-light">
                {cartLength > 0 && cartLength}
              </span>
            </Nav.Link>
            <Button className={(isInFooter && !isMenuOpen) && 'invisible'} variant="link" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              { isMenuOpen
                ? <IoMdClose size={20} />
                : <HiOutlineMenuAlt4 size={20} />
              }
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
