import './App.scss'
import Home from '@components/Home/Home'
import About from '@components/About/About'
import Contact from '@components/Contact/Contact'
import NotFound from '@components/NotFound/NotFound'
import Navigation from '@components/Navigation/Navigation'
import Footer from '@components/Footer/Footer'
import Menu from '@components/Menu/Menu'
import Shop from '@components/Shop/Shop'
import Cart from '@components/Cart/Cart'
import Checkout from '@components/Checkout/Checkout'
import Confirmation from '@components/Checkout/Confirmation'
import Login from '@components/Login/Login'
import Conditions from '@components/Conditions/Conditions'
import { useCallback, useEffect, useState } from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import { Constants } from '../Constants'
import { totalProductQty } from '../Utils'
import { Spinner } from 'react-bootstrap'
import { useRoutes, useRedirect } from 'hookrouter'
import moment from 'moment'
import 'moment/locale/fr'

export default function App() {

  const scrollPosition = useScrollPosition(60)
  const [ isInFooter, setIsInFooter ] = useState(false)
  const [ appHeight, setAppHeight ] = useState(0)
  const [ footerHeight, setFooterHeight ] = useState(0)
  const [ onContentChange, setOnContentChange ] = useState()
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)
  const [ user, setUser ] = useState(undefined)

  // Cart
  const [ cart, setCart ] = useState([])

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem(Constants.cartLocalStorageKey))
    const storedUser = JSON.parse(localStorage.getItem(Constants.userLocalStorageKey))
    //load persisted cart into state if it exists
    if (storedCart) {
      setCart(storedCart)
    }
    if (storedUser) {
      setUser(storedUser)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(Constants.cartLocalStorageKey, JSON.stringify(cart))
    setOnContentChange({})
  }, [ cart ])

  useEffect(() => {
    if (user) {
      localStorage.setItem(Constants.userLocalStorageKey, JSON.stringify(user))
      setOnContentChange({})
    } else {
      localStorage.removeItem(Constants.userLocalStorageKey)
    }
  }, [ user ])

  const addProductToCart = (product, qty) => {

    // Look for item in cart array
    const existingProduct = cart.find(p => {
      if (product.id_product_attribute) {
        return p.id === product.id && p.id_product_attribute === product.id_product_attribute
      } else {
        return p.id === product.id
      }
    })

    // If item already exists
    if (existingProduct) {
      setCart(cart.map(p => {
        if (product.id_product_attribute) {
          if (p.id === product.id && p.id_product_attribute === product.id_product_attribute) {
            p.qty += qty
          }
        } else {
          if (p.id === product.id) {
            p.qty += qty
          }
        }
        return p
      }))
    } else { // If item doesn't exist, simply add it
      setCart([ ...cart, { ...product, qty } ])
    }

  }

  const updateQuantity = (product, qty) => {

    setCart(cart.map(p => {
      if (qty > 0) {
        if (product.id_product_attribute) {
          if (p.id === product.id && p.id_product_attribute === product.id_product_attribute) {
            p.qty = qty
          }
        } else {
          if (p.id === product.id) {
            p.qty = qty
          }
        }
      }
      return p
    }))

  }

  const removeProductFromCart = (product) => {
    setCart(cart.filter(p => {
      if (product.id_product_attribute) {
        if (p.id === product.id && p.id_product_attribute === product.id_product_attribute) {
          return false
        }
      } else {
        return p.id !== product.id
      }
      return true
    }))
  }

  const clearCart = () => {
    localStorage.removeItem(Constants.cartLocalStorageKey)
    setCart([])
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const appRefCallback = useCallback(node => {
    if (node !== null) {
      setAppHeight(node.getBoundingClientRect().height)
    }
    // eslint-disable-next-line
  }, [onContentChange])

  useEffect(() => {
    setIsInFooter(scrollPosition === 0 ? false : scrollPosition >= appHeight - footerHeight - 80)
  }, [ scrollPosition, appHeight, footerHeight ])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [ isLoading ])

  const shop = (link) => <Shop link={link} setIsLoading={setIsLoading} scrollPosition={scrollPosition} addProductToCart={addProductToCart} cart={cart} updateQuantity={updateQuantity} removeProductFromCart={removeProductFromCart} />

  const shopOpeningHours = [
    {
      days: [
        moment().isoWeekday(2),
        moment().isoWeekday(3),
        moment().isoWeekday(4),
        moment().isoWeekday(5)
      ],
      hours: {
        morning: [ '9', '13' ],
        afternoon: [ '15:30', '19' ]
      }
    },
    {
      days: [ moment().isoWeekday(6) ],
      hours: {
        allDay: [ '9', '19' ]
      }
    },
    {
      days: [ moment().isoWeekday(7) ],
      hours: {
        morning: [ '9', '13' ]
      }
    }
  ]

  const routes = {
    '/home': () => <Home />,
    '/about': () => <About />,
    '/contact': () => <Contact shopOpeningHours={shopOpeningHours} />,
    '/shop': () => shop(),
    '/shop/:link': ({ link }) => shop(link),
    '/cart': () => <Cart cart={cart} updateQuantity={updateQuantity} removeProductFromCart={removeProductFromCart} />,
    '/checkout': () => <Checkout user={user} setIsLoading={setIsLoading} cart={cart} updateQuantity={updateQuantity} removeProductFromCart={removeProductFromCart} clearCart={clearCart} setOnContentChange={setOnContentChange} shopOpeningHours={shopOpeningHours} />,
    '/checkout/confirmation/:reference': ({ reference }) => <Confirmation reference={reference} />,
    '/login': () => <Login setUser={setUser} setOnContentChange={setOnContentChange} setIsLoading={setIsLoading} />,
    '/conditions': () => <Conditions />,
  }

  useRedirect('/', '/home')
  const routeResult = useRoutes(routes)

  const menuStyle = {
    opacity: isMenuOpen ? 1 : 0,
    visibility: isMenuOpen ? 'visible' : 'hidden',
    transition: isMenuOpen? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity 300ms',
    zIndex: 1001
  }

  return (
    <div className="App" ref={appRefCallback} onLoad={setOnContentChange}>
      <div className={isLoading ? 'loader d-flex justify-content-center align-items-center' : 'd-none'}>
        <Spinner animation="border" size="xl" />
      </div>
      <Navigation isInFooter={isInFooter} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} cartLength={totalProductQty(cart)} user={user} setUser={setUser} />
      <Menu style={menuStyle} />
      { routeResult || <NotFound /> }
      <Footer footerRef={measuredRef} />
    </div>
  )
}
